<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-6 offset-md-3">
        <h4 class="font-weight-bold">{{ 'testimonialInvitation.title' | translate }}</h4>
      </div>
    </div>
    <div class="mt-5" v-if="loading">
      <loader />
    </div>
    <div class="row mt-5" v-else>
      <div class="col-md-2 offset-md-1">
        <img :src="avatar" alt="profile-image" class="img-fluid" width="100%" height="auto" />
        <input
          type="file"
          ref="photoUpload"
          @change="resizeAndSetImage"
          class="hidden"
          accept="image/gif, image/jpeg, image/png"
        />
        <secondary-button class="btn-block mt-2" @click="uploadPhoto">
          {{ 'settings.uploadPhoto' | translate }}
        </secondary-button>
      </div>
      <div class="col-md-6">
        <form @submit.prevent="updateTestimonials()">
          <div class="form-group">
            <label for="name">{{ 'form.name' | translate }}</label>
            <input
              autocomplete="name"
              type="text"
              class="form-control"
              id="name"
              :placeholder="$t('form.name')"
              v-model="data.name"
              required
            />
          </div>
          <div class="form-group">
            <label for="email">{{ 'form.email' | translate }}</label>
            <input
              autocomplete="email"
              type="email"
              class="form-control"
              id="email"
              :placeholder="$t('form.email')"
              v-model="data.email"
              required
            />
          </div>
          <div class="form-group">
            <label for="job">{{ 'form.jobTitle' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="job"
              :placeholder="$t('form.jobTitle')"
              v-model="data.functionInCompany"
              required
            />
          </div>
          <div class="form-group">
            <label for="location">{{ 'form.location' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="location"
              :placeholder="$t('form.location')"
              v-model="data.location"
              required
            />
          </div>
          <div class="form-group">
            <label>{{ 'form.gender' | translate }}</label>
            <select name="gender" class="form-control" v-model="data.gender">
              <option value="1">{{ 'gender.unknown' | translate }}</option>
              <option value="2">{{ 'gender.male' | translate }}</option>
              <option value="3">{{ 'gender.female' | translate }}</option>
            </select>
          </div>
          <div class="form-group" v-if="company && company.languages.length > 1">
            <label>{{ 'form.language' | translate }}</label>
            <select
              autocomplete="country-name"
              class="form-control"
              name="language-select"
              id="language-select"
              v-model="data.language"
              @change="updateLanguage"
            >
              <option :value="null" disabled>{{ 'settings.changeYourLanguage' | translate }}</option>
              <option v-for="language in company.languages" :value="language.localeCode" :key="language.id">
                {{ language.languageName }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <p>
              <label>{{ 'form.text' | translate }}</label>
            </p>
            <textarea v-model="data.textExperience" id="text-area-editor"></textarea>
          </div>

          <div class="row" v-if="!loadingFlipbase">
            <div class="col-12 form-group">
              <label for="video">{{ 'form.video' | translate }}</label>
              <input name="video" type="flipbase" :value="data.videoExperience" />
            </div>
          </div>

          <primary-button :disabled="updating" type="submit" class="btn btn-secondary mt-2">
            <template v-if="updating">{{ 'general.loading' | translate }}</template>
            <template v-else>{{ 'general.send' | translate }}</template>
          </primary-button>
          <default-button class="ml-2 mt-2" @click="addComment()" :disabled="updating" v-if="!data.comments.length">
            <i class="material-icons float-left mr-1">add</i>
            {{ 'form.addComment' | translate }}
          </default-button>
        </form>
      </div>
    </div>

    <template v-if="!loading && data.comments && data.comments.length">
      <div class="row mt-5">
        <br />
        <div
          class="col-12 col-md-6 offset-md-3"
          v-for="(comment, index) in data.comments"
          :key="index + comment.dateCreated"
        >
          <div class="form-group comment">
            <label v-if="!comment.authorName" translate="form.comment"></label>
            <label v-else>
              {{ comment.authorName }}
              <small v-if="comment.dateCreated">({{ comment.dateCreated | date }})</small>
            </label>
            <p>
              {{ comment.comment }}
              <i
                class="material-icons ml-1 small text-danger pointer"
                v-if="comment.local"
                @click="removeComment(index)"
                >close</i
              >
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <default-button class="mt-2" @click="addComment()" :disabled="updating">
            <i class="material-icons float-left mr-1">add</i>
            {{ 'form.addComment' | translate }}
          </default-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import flipbaseMixin from '../../mixins/flipbase';
import resizeImageMixin from '@app/mixins/resize-image';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';

export default {
  mixins: [flipbaseMixin, resizeImageMixin],
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('settings', ['company']),
    avatar() {
      return (this.data && this.data.photo) || this.user.defaultAvatar;
    }
  },
  mounted() {
    mediumEditor(
      `[id="text-area-editor"]`,
      ['bold', 'italic', 'bulletList', 'colorPicker', 'anchor'],
      (editorElement) => {}
    );
  },
  filters: {
    date(value) {
      return new Date(new Date(value).toUTCString()).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }); // moment.utc(value).local().format("D MMMM YYYY, HH:mm");
    }
  },
  created() {
    const { invitationCode } = this.$route.params;
    if (!invitationCode) {
      this.$notify({
        type: 'error',
        message: this.$t('notifications.missingInvitationCode'),
        position: 'bottom-right'
      });
      setTimeout(() => {
        this.$router.push('/dashboard');
      }, 1000);
      return;
    }

    this.$http
      .get(`/dashboard/testimonials/invitation/${invitationCode}`)
      .then(
        (response) => {
          this.data = response.data;
          this.data.language = this.user.language;
          this.data.invitationCode = invitationCode;
          this.loadFlipbase();
        },
        (err) => {
          let errorMessage = this.$t('notifications.error');
          if (err && err.errorMessage) {
            errorMessage = err.errorMessage;
          } else if (err && err.data && err.data.errorMessage) {
            errorMessage = err.data.errorMessage;
          } else if (err && err.statusText) {
            errorMessage = err.statusText;
          }

          this.$notify({
            type: 'error',
            message: errorMessage,
            position: 'bottom-right'
          });

          setTimeout(() => {
            this.$router.push('/dashboard');
          }, 1000);
        }
      )
      .finally(() => {
        this.loading = false;
      });
    return;
  },
  data() {
    return {
      editorOptions: {
        placeholder: ' ',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['link']
          ]
        }
      },
      data: {},
      loading: true,
      updating: false
    };
  },

  methods: {
    addComment() {
      this.$prompt(this.$t('form.addComment'), {
        confirmButtonText: this.$t('general.save'),
        cancelButtonText: this.$t('general.cancel'),
        inputPlaceholder: this.$t('form.comment')
      }).then(({ value }) => {
        this.data.comments.push({
          authorName: this.data.name,
          comment: value,
          dateCreated: new Date(),
          local: true
        });
      });
    },
    async resizeAndSetImage(event) {
      const file = event.target.files[0];

      const size = file.size;
      const sizeInKb = size / 1024; // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
      const sizeInMb = sizeInKb / 1024; // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
      if (sizeInMb > 20) {
        return this.$notify({
          type: 'error',
          message: this.$t('notifications.wrongPictureFormat'),
          position: 'bottom-right'
        });
      }

      const config = {
        file,
        size: 500
      };
      const resizedImage = await this.resizeImage(config, true);
      this.$set(this.data, 'photo', resizedImage);
      Vue.nextTick();
    },
    loadFlipbase() {
      const { companyId } = this.user;

      this.$http.get(`/companies/${companyId}/flipbase`).then((response) => {
        if (!response.data.recorderId || !response.data.playerId) {
        } else {
          this.lazyLoadFlipbaseRecorder().then(() => {
            setTimeout(() => {
              window.Flipbase.recorder(
                {
                  recorderId: response.data.recorderId,
                  maxHeight: 300,
                  locale: this.data.language,
                  callback: (id) => {
                    this.data.videoExperience = id;
                  }
                },
                document.getElementById('flipbase-recorder')
              );
            }, 400);
          });
        }
      });
    },
    removeComment(index) {
      this.data.comments.splice(index, 1);
    },
    updateLanguage() {
      // Vue.i18n.set(this.data.language);
    },
    updateTestimonials() {
      this.updating = true;
      this.data.comments = this.data.comments.filter((item) => !!item.comment && item.comment !== ''); // only send the ones that have values.
      this.$http
        .post('/dashboard/testimonials', {
          ...this.data,
          gender: '' + this.data.gender
        })
        .then(
          (response) => {
            this.$notify({
              type: 'success',
              message: this.$t('notifications.answersSaved'),
              position: 'bottom-right'
            });

            setTimeout(() => {
              this.$router.push('/dashboard');
            }, 600);
          },
          (err) => {
            let errorMessage = this.$t('notifications.error');
            if (err && err.errorMessage) {
              errorMessage = err.errorMessage;
            } else if (err && err.statusText) {
              errorMessage = err.statusText;
            }

            this.$notify({
              type: 'error',
              message: errorMessage,
              position: 'bottom-right'
            });
          }
        )
        .finally(() => {
          this.updating = false;
        });
    },
    uploadPhoto() {
      this.$refs.photoUpload.click();
    }
  }
};
</script>

<style lang="css" src="@lp/components/mixins/medium-editor.css"></style>
<style lang="scss" scoped>
.container {
  padding-bottom: 24px;
}

label {
  font-weight: bold;
}
</style>

<style lang="scss"></style>
